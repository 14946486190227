type AudioEndedEventListener = () => void;

export interface AppApi {
  audioUrls: string[],
  audioElement: HTMLAudioElement | null,
  audioEndedListener?: AudioEndedEventListener,
  downloadAudio(url: string, audioId: number): boolean,
  getAudioVolume(): number,
  setAudioVolume(volume: number): void,
  playAudio(audioId: number, loop: boolean): Promise<void>,
  playAudioUrl(audioUrl: string, loop: boolean): Promise<void>,
  stopAudio(): void,
  log(message: string): void,
  setAudioEndedEventListener(listener: AudioEndedEventListener): void,
  unsetAudioEndedEventListener(): void,
}

export const browserModeApi: AppApi = {
  audioUrls: [],
  audioElement: null,
  audioEndedListener: undefined,
  downloadAudio(url: string, audioId: number): boolean {
    if (Math.random() < 0.00)
      return false;
    this.audioUrls[audioId] = url;
    return true;
  },
  getAudioVolume: function () {
    if (!this.audioElement) return 0;
    return this.audioElement.volume;
  },
  setAudioVolume(volume: number): void {
    if (!this.audioElement) return;
    this.audioElement.volume = volume;
  },
  async playAudio(audioId: number, loop: boolean): Promise<void> {
    if (!this.audioElement) return;
    this.audioElement.setAttribute("src", this.audioUrls[audioId]);
    await this.audioElement.play();
    this.audioElement.loop = loop;
  },
  async playAudioUrl(audioUrl: string, loop: boolean): Promise<void> {
    if (!this.audioElement) return;
    this.audioElement.setAttribute("src", audioUrl);
    await this.audioElement.play();
    this.audioElement.loop = loop;
  },
  stopAudio(): void {
    if (!this.audioElement) return;
    this.audioElement.pause();
  },
  log(message: string): void {
    if (process.env.VUE_APP_DEV === 'true')
      // eslint-disable-next-line no-console
      console.log(Date.now() + ' ' + message);
  },
  setAudioEndedEventListener(listener: AudioEndedEventListener): void {
    if (!this.audioElement) return;
    this.audioEndedListener = listener;
    this.audioElement.addEventListener('ended', listener);
  },
  unsetAudioEndedEventListener(): void {
    if (!this.audioElement) return;
    if (!this.audioEndedListener) return;
    this.audioElement.removeEventListener('ended', this.audioEndedListener);
  }
}
