<template>
  <router-view />
</template>

<style>
.test-scroll__header {
  overflow-x: scroll;
  padding-right: 10px;
}
.test-scroll__header::-webkit-scrollbar {
  display: none;
}
.mb-1 {
  margin-bottom: 30px;
}
</style>