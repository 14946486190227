import { createApp, InjectionKey } from 'vue'
import App from './App.vue'
import router from './router'

import '@/assets/scss/style.scss'
import { JetApp, jetApp } from "@/store/jetapp";
import { Timer, timer } from "@/store/timer";
import { Exam, exam} from "@/store/exam";

const app = createApp(App)

app.use(router).mount('#app')
export const examKey: InjectionKey<Exam> = Symbol('exam');
export const appKey: InjectionKey<JetApp> = Symbol('app');
export const timerKey: InjectionKey<Timer> = Symbol('timer');
app.provide(examKey, exam)
app.provide(appKey, jetApp)
app.provide(timerKey, timer)
