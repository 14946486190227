<script setup>
import { inject, ref, watch, onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import Modal from '@/components/parts/Modal.vue'
import { appKey, examKey } from "@/main";

const route = useRoute();
const router = useRouter();

const app = inject(appKey);
const exam = inject(examKey)

const audio = ref(null);
const classLevel = ref('');
const gakken = exam?.isGakken();
const demo = exam?.isDemo();

const group = route.params.group;
const pageName = ref(route.name);

const training = ref(false);

const visibleErrorModal = ref(false);

const questions = ref();

const resume = async() => {
  const session = await exam.session();

  if (session.endTime != null) {
    router.replace(`/cbt/${group}/end`);
  }
  // リーディングから再開する場合
  else if (session.readingStartTime != null && session.readingEndTime == null && pageName.value !== 'reading') {
    router.replace(`/cbt/${group}/reading/${session.answer.readingProgress}`);
  }
  // リスニングの途中でリロードされた場合
  else if (session.listeningStartTime != null && session.listeningEndTime == null && pageName.value !== 'volume') {
    router.replace(`/cbt/${group}/volume`);
  }
}

const onPageChange = async() => {
  pageName.value = route.name.split('_')[0];

  if (route.name === 'start') {
    await loadData();
  }
}

const overridePageName = async(name) => {
  pageName.value = name;
  training.value = name === 'training-test';
}

const loadData = async() => {
  if (route.name === 'login') {
    return;
  }

  showLoading(true);
  try {
    questions.value = await exam.questions();
    classLevel.value = 'lv' + (questions.value.rank.split('-')[0]);
    await resume();
    showLoading(false);
  }
  catch(e) {
    showLoading(false);
    visibleErrorModal.value = true;
  }
}

const showLoading = (show) => {
  document.getElementById('loading').style.display = show ? 'block' : 'none';
}

const closeWindow = () => {
  window.close();
}

const backToLoginForm = () => {
  const path = location.pathname.replace(/^(\/cbt\/[^/]+).*/, '$1/login');
  location.replace(path);
}

onMounted(async () => {
  app.setAudioElement(audio.value);
  setResizeHandler();
  await loadData();
  showLoading(false);
});

const underDevelopment = computed(() => {
  return process.env.VUE_APP_DEV === 'true';
});

watch(() => route.name, onPageChange);

const setResizeHandler = () => {
  // サイズ調整用
  const changeScale = {
    "windowWidth": 1180,
    "windowHeight": 820,
    "percent": 1,
    "function": function() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
      let ratio = window.innerHeight / document.documentElement.clientWidth;
      let scale;
      let scaleStyle;
      let length;
      if (changeScale.percent === window.devicePixelRatio) {
        if (ratio < 0.65) {
          length = changeScale.windowHeight;
          scale = document.documentElement.clientHeight;
        } else {
          length = changeScale.windowWidth;
          scale = document.documentElement.clientWidth;
        }
        scale = scale / length;
        scaleStyle = "scale(" + scale + ")";
        document.getElementById("js-container").style.transform = scaleStyle;
        if (document.getElementById("js-test-count") !== null) {
          document.getElementById("js-test-count").style.transform = scaleStyle + ' rotate(-90deg)';
          document.getElementById("js-test-count").style.top = (15 * scale) + 'px';
          document.getElementById("js-test-count").style.right = (15 * scale) + 'px';
        }
        document.getElementById("js-container").classList.add('is-show');
      } else {
        changeScale.percent = window.devicePixelRatio;
      }
    }
  };

  changeScale.function();

  window.addEventListener("resize", () => {
    changeScale.function();
  });
  window.addEventListener('load', () => {
    changeScale.function();
    let countDownObj = document.getElementById("js-test-count");
    if (countDownObj !== null) {
      countDownObj.classList.add('is-animation-start');
      countDownObj.addEventListener('animationend', () => {
        countDownObj.classList.add('is-animation-end');
      });
    }
    let iconScrollObj = document.getElementById("js-icon-scroll");
    let scrollBoxObj = document.getElementById("js-test-scroll");
    if (iconScrollObj !== null) {
    iconScrollObj.addEventListener('click', function () {
      scrollBoxObj.scrollTo({
        top: 100,
        behavior: 'smooth'
      });
    });
    }
    if (scrollBoxObj !== null) {
      scrollBoxObj.onscroll = function () {
        if (this.scrollTop > 2) {
          iconScrollObj.classList.add('is-hide');
        } else {
          iconScrollObj.classList.remove('is-hide');
        }
      }
    }
  });
}
</script>

<template>
  <div class="l-wrap" v-bind:class="classLevel" :id="pageName">
    <div class="l-wrap__inner" v-bind:class="{'bg-color':training}">
      <div v-if="demo" class="under-sample">SAMPLE</div>
      <div v-if="underDevelopment" class="under-development">⚠ This application is currently under development</div>
      <div>
        <div class="l-container" id="js-container" style="opacity: 0;">
          <router-view @overridePageName="overridePageName"></router-view>
        </div>
      </div>
    </div>
  </div>
  <audio ref="audio" />
  <teleport to="body">
    <div id="loading">
      <div class="overlay"></div>
      <div class="loading-wrap">
        <div class="loading">
          <div class="loader"></div>
        </div>
      </div>
      <div class="loading-text">問題データ読み込み中</div>
    </div>
    <Modal :visible="visibleErrorModal" @close="visibleErrorModal = false">
      <div class="errorModal" v-if="gakken">
        <div>問題データの読み込みに失敗しました。<br>マイページに戻って再度「JETを受検する」ボタンを押してください。</div>
        <div>
          <a @click="closeWindow()" class="m-btn is-center" v-if="gakken"><span>閉じる</span></a>
        </div>
      </div>
      <div class="errorModal" v-else>
        <div>問題データの読み込みに失敗しました。</div>
        <div>
          <a @click="backToLoginForm()" class="m-btn is-center" v-if="gakken"><span>ログイン画面に戻る</span></a>
        </div>
      </div>
    </Modal>
  </teleport>
</template>

<style lang="scss" scoped>
.errorModal {
  line-height: 1.5;
  >:nth-child(1) {
    margin-top: 50px;
  }
  >:nth-child(2) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
.under-development {
  position: absolute;
  bottom: 25px;
  right: 25px;
  font-weight: bold;
  font-size: 25px;
  color: #808080;
  text-shadow: white 1px 0 3px;
  z-index: 1;
}

.under-sample {
  position: absolute;
  bottom: 100px;
  right: -120px;
  font-weight: bold;
  font-size: 40px;
  letter-spacing: 0.12em;
  color: #000000cc;
  z-index: 2;
  transform: rotate(-45deg);
  padding: 20px 200px;
  background-image: repeating-linear-gradient(
    -45deg,
    #ff000050,
    #ff000050 8px,
    #ffffff50 8px,
    #ffffff50 20px
  );
  background-position: center bottom;
  background-repeat: repeat-x;
  background-size: auto 80px;
}
</style>
