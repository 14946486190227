import axios from 'axios'

const BASE_URI = window.location.origin;
const BASE_API_URI = BASE_URI + "/api/";
const BASE_AUDIO_URI = BASE_API_URI + "exam/audio/";

const httpClient = axios.create();
httpClient.defaults.baseURL = BASE_API_URI;
httpClient.defaults.timeout = 30000;

export interface SiteInfo {
  code: string;
  name: string;
  cbtCode: string;
}

export interface Answer {
  listening: Array<number>,
  played: Array<number>,
  reading: Array<number>,
  listeningProgress: number,
  readingProgress: number,
}

export interface WrongMarks {
  name: boolean,
  nameEn: boolean,
  schoolGrade: boolean,
  sex: boolean,
  dateOfBirth: boolean
}

export interface SessionData {
  answer: Answer,
  error: boolean,
  authTime: number,
  listeningStartTime: number | null,
  listeningEndTime: number | null,
  readingStartTime: number | null,
  endTime: number,
  listeningTimeRemaining: number,
  readingTimeRemaining: number,
  volume: number,
  cbtCode: string,
  reachedLastQuestion: boolean,
  wrongMarks: WrongMarks,
  token: string | null,
  selected: number | null;
}

export interface Part {
  partNumber: number,
  layout: number,
  directionEn: string,
  directionJa: string
}

export interface PartInfo {
  number: number,
  questionCount: number
}

export interface AnswerInfo {
  page: number,
  questionNumber: number,
  answer: number
}

export interface PartWithAnswers {
  number: number,
  answers: AnswerInfo[]
}

interface Choice {
  text: string | undefined,
  image: string | undefined
}

interface Question {
  questionNumber: number,
  questionText: string,
  choices: Choice[],
  answer: number | undefined,
  explanation: string | undefined
}

export interface Page {
  partNumber: number,
  pageNumber: number,
  example: boolean,
  audio: string | undefined,
  audioDuration: number | undefined,
  image: string | undefined,
  questions: Question[]
}

interface Section {
  notice: string | undefined,
  timeLimit: number | undefined,
  parts: Part[],
  pages: Page[]
}

export interface QuestionData {
  rank: string,
  name: string,
  listening: Section,
  reading: Section,
  images: { [key: string]: string }
}

interface Examinee {
  examineeNumber: string,
  name: string,
  nameEn: string,
  schoolGrade: string,
  sex: string,
  dateOfBirth: string
}

let questionData: QuestionData | null = null;
let sessionData: SessionData | null = null;

const EXAM_TOKEN_KEY = 'examToken';

function getLocalToken() {
  return localStorage.getItem(EXAM_TOKEN_KEY);
}
function setLocalToken(token: string) {
  localStorage.setItem(EXAM_TOKEN_KEY, token)
}
function clearLocalToken() {
  localStorage.removeItem(EXAM_TOKEN_KEY);
}

export interface Exam {
  siteLogin(authCode: string): Promise<SiteInfo>,
  siteInfo(cbtCode: string): Promise<SiteInfo>,
  login(examineeNumber: string, password: string, cbtCode: string): Promise<SessionData>,
  demoLogin(examGradeCode: string): Promise<SessionData>,
  logout(): Promise<void>,
  examinee(): Promise<Examinee>,
  questions(): Promise<QuestionData>,
  session() : Promise<SessionData>,
  saveSession(): Promise<void>,
  updateSession(value: SessionData): Promise<void>,
  answerListening(page: number, value: number): Promise<void>,
  answerReading(page: number, value: number): Promise<void>,
  listeningStart(): Promise<void>,
  listeningEnd(): Promise<void>,
  readingStart(): Promise<void>,
  readingEnd(): Promise<void>,
  createPartsList(questions: Section): Promise<PartInfo[]>,
  createPartsListWithAnswers(questions: Section): Promise<PartWithAnswers[]>,
  getAudioUrl(): string,
  isDebug(): boolean,
  isDev(): boolean,
  isGakken(): boolean,
  isDemo(): boolean,
  isOfficial(): boolean,
  setReachedLastQuestion(): Promise<void>,
  isReachedLastQuestion(): Promise<boolean>,
  gakkenLogin(token: string): Promise<void>,
  startFullScreen(): void,
  endFullScreen(): void
}

export const exam: Exam = {
  /**
   * 会場識別コード認証
   */
  async siteLogin(authCode: string) {
    let res;
    try {
      res = await httpClient.post('site/login', {authCode});
    }
    catch(e) {
      throw new Error('会場情報取得に失敗しました。');
    }
    return res.data;
  },
  /**
   * 会場情報取得
   * @param cbtCode
   */
  async siteInfo(cbtCode: string) {
    let res;
    try {
      res = await httpClient.get('site/' + cbtCode);
    }
    catch(e) {
      throw new Error('会場情報取得に失敗しました。');
    }
    return res.data;
  },
  /**
   * ログイン
   */
  async login(examineeNumber: string, password: string, cbtCode: string) {
    let res;
    try {
      res = await httpClient.post('login', {examineeNumber, password, cbtCode})
    }
    catch(e) {
      throw new Error('ログインにしっぱいしました。');
    }
    const session = res.data;
    const token = getLocalToken();
    if (session.token && token !== session.token) {
      throw new Error('このじゅけんばんごうはすでにつかわれています。');
    }
    return res.data;
  },
  /**
   * DEMOログイン
   */
  async demoLogin(examGradeCode: string) {
    let res;
    try {
      res = await httpClient.post('login', {examGradeCode})
    }
    catch(e) {
      throw new Error('ログインにしっぱいしました。');
    }
    return res.data;
  },
  /**
   * ログアウト
   */
  async logout() {
    try {
      questionData = null;
      sessionData = null;
      await httpClient.post('logout')
    }
    catch(e) {
      throw new Error('Logout failed.');
    }
  },
  /**
   * 問題データの取得（音声を除く）
   */
  async examinee() {
    try {
      const res = await httpClient.get('exam/examinee');
      return res.data;
    }
    catch(e) {
      throw new Error('Cannot get examinee.');
    }
  },
  /**
   * 問題データの取得（音声を除く）
   */
  async questions() {
    if (questionData) {
      return questionData;
    }

    try {
      const res = await httpClient.get('exam/questions');
      questionData = res.data as QuestionData
      return questionData;
    }
    catch(e) {
      throw new Error('Cannot get question data.');
    }
  },
  /**
   * セッションデータの取得
   */
  async session() : Promise<SessionData> {
    if (sessionData) {
      return sessionData;
    }

    try {
      const res = await httpClient.get('exam/session');
      sessionData = res.data as SessionData;
      return sessionData;
    }
    catch(e) {
      throw new Error('Cannot get session data.'); // TODO: 仕様を決める
    }
  },
  async saveSession() {
    try {
      await httpClient.post('exam/session', {sessionData});

    }
    catch(e) {
      throw new Error('Cannot save session data.'); // TODO: 仕様を決める
    }
  },
  async updateSession(value: SessionData) {
    sessionData = value;
    await this.saveSession();
  },
  async answerListening(page: number, value: number) {
    const session = await this.session();
    session.answer.listening[page] = value;
    await this.updateSession(session);
  },
  async answerReading(page: number, value: number) {
    const session = await this.session();
    session.answer.reading[page] = value;
    session.selected = value;
    await this.updateSession(session);
  },
  async listeningStart() {
    if (!this.isGakken()) {
      const res = await httpClient.get('exam/session');
      const session = res.data;
      if (session.token) {
        if (getLocalToken() !== session.token) {
          throw new Error('このじゅけんばんごうはすでにつかわれています。');
        }
      }
    }
    const res = await httpClient.post('exam/listening-start');
    const session = res.data;
    setLocalToken(session.token);
    sessionData = res.data;
  },
  async listeningEnd() {
    const res = await httpClient.post('exam/listening-end');
    sessionData = res.data;
  },
  async readingStart() {
    const res = await httpClient.post('exam/reading-start');
    sessionData = res.data;
  },
  async readingEnd() {
    const res = await httpClient.post('exam/reading-end');
    sessionData = res.data;
    clearLocalToken();
  },
  async createPartsList(questions: Section) {
    const parts: PartInfo[] = [];
    if (questions.pages.length == 0) return parts;

    const getPart = (partNumber: number) => {
      let part = parts.find(part => part.number == partNumber);
      if (part) return part;
      part = { number: partNumber, questionCount: 0 };
      parts.push(part);
      return part;
    };

    for (const page of questions.pages) {
      const part = getPart(page.partNumber);
      if (!page.example) part.questionCount++;
    }

    return parts;
  },
  async createPartsListWithAnswers(questions: Section) {
    const parts: PartWithAnswers[] = [];
    if (questions.pages.length == 0) return parts;

    const session = await this.session();

    const getPart = (partNumber: number) => {
      let part = parts.find(part => part.number == partNumber);
      if (part) return part;
      part = { number: partNumber, answers: [] };
      parts.push(part);
      return part;
    };

    for (let i = 0; i < questions.pages.length; i++) {
      const page = questions.pages[i];
      const part = getPart(page.partNumber);
      if (page.example) continue;
      part.answers.push({
        page: i,
        questionNumber: page.questions[0].questionNumber,
        answer: session.answer.reading[i]
      });
    }
    console.log(parts);
    return parts;
  },
  /**
   * 音声URIの取得
   */
  getAudioUrl(): string {
    return BASE_AUDIO_URI;
  },
  isDebug(): boolean {
    return process.env.VUE_DEBUG === 'true';
  },
  isDev(): boolean {
    return process.env.VUE_APP_DEV === 'true';
  },
  isGakken(): boolean {
    return process.env.VUE_APP_MODE === 'gakken';
  },
  isDemo(): boolean {
    return process.env.VUE_APP_MODE === 'demo';
  },
  isOfficial(): boolean {
    return process.env.VUE_APP_MODE === 'official';
  },
  async setReachedLastQuestion() {
    const session = await this.session();
    session.reachedLastQuestion = true;
    await this.updateSession(session);
  },
  async isReachedLastQuestion(): Promise<boolean> {
    const session = await this.session();
    return session.reachedLastQuestion;
  },
  async gakkenLogin(token: string) {
    try {
      await httpClient.post('gakken-login', { token })
    }
    catch(e) {
      throw new Error('Login failed.');
    }
  },
  startFullScreen(): void {
    if ('ontouchend' in document) return;
    if (document.body.requestFullscreen) {
      document.body.requestFullscreen();
    }
  },
  endFullScreen(): void {
    if ('ontouchend' in document) return;
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
}
