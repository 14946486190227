import { browserModeApi, AppApi} from './browserMode'

type AudioEndedEventListener = () => void;

export interface JetApp {
  api: AppApi
  audioEndedListener: AudioEndedEventListener | null,
  downloadAudio(url: string, audioId: number): boolean,
  getAudioVolume(): number,
  setAudioVolume(volume: number): void,
  playAudio(audioId: number, loop: boolean, listener?: AudioEndedEventListener): Promise<void>,
  playAudioUrl(audioUrl: string, loop: boolean, listener?: AudioEndedEventListener): Promise<void>,
  stopAudio(): void,
  log(message: string): void,
  setAudioEndedEventListener(listener: AudioEndedEventListener): void,
  unsetAudioEndedEventListener(): void,
  getAudioElement(): HTMLAudioElement | null,
  setAudioElement(audio: HTMLAudioElement): void
}

export const jetApp: JetApp = {
  api: browserModeApi,
  audioEndedListener: null,
  downloadAudio(url: string, audioId: number): boolean {
    return this.api.downloadAudio(url, audioId);
  },
  getAudioElement(): HTMLAudioElement | null {
    return browserModeApi.audioElement;
  },
  setAudioElement(audio: HTMLAudioElement): void {
    browserModeApi.audioElement = audio;
  },
  getAudioVolume(): number {
    return this.api.getAudioVolume();
  },
  setAudioVolume(volume: number): void {
    this.api.setAudioVolume(volume);
  },
  async playAudio(audioId: number, loop: boolean, listener?: AudioEndedEventListener): Promise<void> {
    if (listener) {
      this.unsetAudioEndedEventListener();
      this.setAudioEndedEventListener(listener);
    }
    await this.api.playAudio(audioId, loop);
  },
  async playAudioUrl(audioUrl: string, loop: boolean, listener?: AudioEndedEventListener): Promise<void> {
    if (listener) {
      this.unsetAudioEndedEventListener();
      this.setAudioEndedEventListener(listener);
    }
    await this.api.playAudioUrl(audioUrl, loop);
  },
  stopAudio(): void {
    this.api.stopAudio();
  },
  log(message: string): void {
    this.api.log(message);
  },
  setAudioEndedEventListener(listener: AudioEndedEventListener): void {
    if (this.api == browserModeApi) {
      this.api.setAudioEndedEventListener(listener);
    } else {
      this.audioEndedListener = listener;
    }
  },
  unsetAudioEndedEventListener(): void {
    if (this.api == browserModeApi) {
      this.api.unsetAudioEndedEventListener();
    } else {
      this.audioEndedListener = null;
    }
  }
}
