<template>
  <div class="l-wrap" v-bind:class="classLevel" :id="pageName">
    <div class="l-wrap__inner" v-bind:class="{'bg-color':training}">
      <div v-if="underDevelopment" class="under-development">⚠ This application is currently under development</div>
      <div>
        ページが見つかりません
      </div>
    </div>
  </div>
</template>
