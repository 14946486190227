import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Exam from '../pages/Exam.vue'
import Login from '../pages/exam/Login.vue'
import Start from '../pages/exam/Start.vue'
import Volume from '../pages/exam/Volume.vue'
import ExamStart from '../pages/exam/ExamStart.vue'
import Caution from '../pages/exam/Caution.vue'
import ListeningTest from '../pages/exam/ListeningTest.vue'
import Flow from '../pages/exam/Flow.vue'
import Training from '../pages/exam/Training.vue'
import ReadingCaution from '../pages/exam/ReadingCaution.vue'
import ReadingTest from '../pages/exam/ReadingTest.vue'
import Confirm from '../pages/exam/Confirm.vue'
import End from '../pages/exam/End.vue'
import SiteLogin from '../pages/SiteLogin.vue'
import DemoLogin from '../pages/DemoLogin.vue'
import NotFound from '../pages/NotFound.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/cbt/:group',
    component: Exam,
    children: [
      {
        path: '', name: 'Home', redirect: to => {
          return {path: '/cbt/' + to.params.group + '/login'}
        }
      },
      {path: 'login', name: 'login', component: Login},
      {path: 'start', name: 'start', component: Start},
      {path: 'volume', name: 'volume', component: Volume},
      {path: 'exam-start', name: 'exam_start', component: ExamStart},
      {path: 'caution', name: 'caution', component: Caution},
      {path: ':examtype/:page/flow', name: 'flow', component: Flow},
      {path: ':examtype/:page/training', name: 'training', component: Training},
      {path: 'listening/:page', name: 'test', component: ListeningTest},
      {path: 'reading-caution', name: 'caution_reading', component: ReadingCaution},
      {path: 'reading/:page', name: 'test_reading', component: ReadingTest},
      {path: 'confirm/:mode', name: 'last', component: Confirm},
      {path: 'end', name: 'caution_end', component: End},
    ]
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound
  }
];

if (process.env.VUE_APP_MODE === 'official') {
  routes.push({
    path: '/',
    component: SiteLogin
  })
}
if (process.env.VUE_APP_MODE === 'demo') {
  routes.push({
    path: '/',
    component: DemoLogin
  })
}

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  document.title = `JET Junior English Test${to.meta.title ? ' | ' + to.meta.title : ''}`;
  next();
});

export default router
