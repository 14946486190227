type TickCallback = (timeRemaining: number) => void;
type TimeoutCallback = () => void;

export interface Timer {
  initialTime: number,
  timeRemaining: number,
  tickCallback: TickCallback | null,
  timeoutCallback: TimeoutCallback | null,
  startTime: number | null,
  stopped: boolean,
  init(initialTime: number, tickCallback: TickCallback | null, timeoutCallback: TimeoutCallback | null): void,
  start(): void,
  stop(): void
}

export const timer: Timer = {
  initialTime: 0,
  timeRemaining: 0,
  tickCallback: null,
  timeoutCallback: null,
  startTime: null,
  stopped: true,
  init(initialTime: number, tickCallback: TickCallback | null, timeoutCallback: TimeoutCallback | null): void {
    this.initialTime = initialTime;
    this.timeRemaining = initialTime;
    this.tickCallback = tickCallback;
    this.timeoutCallback = timeoutCallback;
    if (tickCallback) tickCallback.apply(this, [this.timeRemaining]);
  },
  start(): void {
    this.startTime = new Date().getTime() - (this.initialTime - this.timeRemaining) * 1000;
    const tick = () => {
      if (this.stopped) return;
      const date = new Date();
      const elapsedTime = (date.getTime() - (this.startTime || 0)) / 1000;
      if (this.timeRemaining != this.initialTime - elapsedTime) {
        this.timeRemaining = this.initialTime - elapsedTime;
        if (this.timeRemaining <= 0) {
          this.timeRemaining = 0;
          if (this.tickCallback) this.tickCallback.apply(this, [0]);
          if (this.timeoutCallback) this.timeoutCallback.apply(this);
          return;
        }
        if (this.tickCallback) this.tickCallback.apply(this, [this.timeRemaining]);
      }
      window.setTimeout(tick, 200);
    };
    this.stopped = false;
    tick();
  },
  stop(): void {
    this.stopped = true;
  }
}
