<script setup>
import { ref, inject, defineEmits, defineProps } from 'vue'
import { useRouter } from 'vue-router'
import { appKey, examKey } from "@/main";

const router = useRouter();
const app = inject(appKey);
const exam = inject(examKey)

const emits = defineEmits(['next', 'prev']);
defineProps({
  prev: { type: Boolean, default: true },
  next: { type: Boolean, default: true }
});

const demo = exam?.isDemo();
const confirm = ref(false);

const confirmEnd = () => {
  confirm.value = true;
}

const end = () => {
  app?.stopAudio();
  exam.endFullScreen();
  router.replace(`/cbt/demo/login`);
}
</script>

<template>
  <teleport to="body">
    <div v-if="demo" class="demo-control">
      <div v-if="!confirm">
        <p class="rights">本サイト内のすべてのコンテンツ（テキスト、画像、音声等）は著作権法により保護されています。無断での複製、引用、転載、スクリーンショット等の行為は固く禁じられております。</p>
        <button v-if="prev" class="prev" @click="emits('prev')">&laquo; 前へ</button>
        <button v-if="next" class="next" @click="emits('next')">次へ &raquo;</button>
        <button class="end" @click="confirmEnd">終了する</button>
      </div>
      <div v-else>
        <p class="confirm">終了してもよろしいですか？</p>
        <button class="yes" @click="end">はい</button>
        <button class="no" @click="confirm = false">いいえ</button>
      </div>
    </div>
  </teleport>
</template>

<style lang="scss" scoped>
.demo-control {
  display: block;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 50vw;
  z-index: 10;
  font-size: 2vh;
  background-color: #ffffff;
  border-radius: 2px;
  border: 1px solid #808080;
  box-shadow: 2px 2px 10px #000000;
  padding: 0.5vh 1vw;

  p {
    display: inline-block;
    width: 26vw;
    &.rights {
      color: #e44;
      margin-right: 1vw;
      font-size: 1vh;
      font-weight: bold;
      vertical-align: middle;
    }
    &.confirm {
      margin-right: 1vw;
      font-size: 2vh;
      vertical-align: middle;
    }
  }

  button {
    margin: 1vh 0.25vh 1vh;
    font-size: 1.5vh;
    font-weight: bold;
    padding: 0.5vh 1vh;
    color: #ffffff;
    border-radius: 1vh;

    &.prev,
    &.next,
    &.yes,
    &.no {
      width: 6vw;
    }
    &.end {
      width: 7vw;
    }
    &.prev,
    &.next,
    &.no {
      background-color: #3287e5;
    }
    &.yes,
    &.end {
      background-color: #ff6600;
    }
  }
}
</style>
